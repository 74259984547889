import { Component } from '@angular/core'
import { RouterModule, Routes } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  isNavbarCollapsed: boolean = true;
  constructor() { }
  // logout() {
  //   this.userService.logout()
  // }
}
