import { Injectable } from '@angular/core'

import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Requerente } from './requerente';

@Injectable()
export class RequerenteApi {
    constructor(private http: HttpClient) { }

    getRequerenteByNome(nome){
        return this.http.get<Array<Requerente>>(`${environment.serverHost}/requerenteService/requerenteByNome/${nome}`);    
    }

    getRequerenteByDocumento(documento){
        return this.http.get<Array<Requerente>>(`${environment.serverHost}/requerenteService/requerenteByDocumento/${documento}`);    
    }

}
