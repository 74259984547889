import { Injectable } from '@angular/core'
import { Subject, of, empty } from 'rxjs'
import { Observable } from 'rxjs'
import { switchMap, delay } from 'rxjs/operators';

@Injectable()
export class AlertService {
  constructor() {
    this.alert$.pipe(
      switchMap(msg => !msg ? empty() : of(1).pipe(delay(5000)))
    ).subscribe(() => this.alert({}))
  }

  // Observable string sources
  private alertSource = new Subject<MessagePayload>()

  // Observable string streams
  alert$ = this.alertSource.asObservable()

  // Service message commands
  alert(message: Message) {
    const type = Object.keys(message)[0]
    this.alertSource.next({ type, message: type ? message[type] : '' })
  }

  alertAsync(msg: Observable<string>) {
    msg.subscribe(success => this.alert({ success }), danger => this.alert({ danger }))
  }

  primary(msg: string) {
    this.alertSource.next({ type: 'primary', message: msg })
  }

  secondary(msg: string) {
    this.alertSource.next({ type: 'secondary', message: msg })
  }

  success(msg: string) {
    this.alertSource.next({ type: 'success', message: msg })
  }

  danger(msg: string) {
    this.alertSource.next({ type: 'danger', message: msg })
  }

  warning(msg: string) {
    this.alertSource.next({ type: 'warning', message: msg })
  }

  info(msg: string) {
    this.alertSource.next({ type: 'info', message: msg })
  }

  light(msg: string) {
    this.alertSource.next({ type: 'light', message: msg })
  }

  dark(msg: string) {
    this.alertSource.next({ type: 'dark', message: msg })
  }
}

export class Message {
  primary?: string
  secondary?: string
  success?: string
  danger?: string
  warning?: string
  info?: string
  light?: string
  dark?: string
}

export class MessagePayload {
  type: string
  message: string
}
