import { Injectable } from '@angular/core'

import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PesquisaResolucaoEmenta } from './pesquisa-resolucao-ementa';

@Injectable()
export class PesquisaResolucaoEmentaApi {
    
    constructor(private http: HttpClient) { 
        
    }

    getPesquisaByNumeroResolucao(numeroResolucao){
        return this.http.get<Array<PesquisaResolucaoEmenta>>(`${environment.serverHost}/resolucaoEmentaService/getByNumeroResolucao/`, {params: {numeroResolucao: numeroResolucao}});    
    }

    getPesquisaTextual(tipoBusca, localBusca, palavra){
        return this.http.get<Array<PesquisaResolucaoEmenta>>(`${environment.serverHost}/resolucaoEmentaService/getTextual/`, {params: {tipoBusca: tipoBusca, localBusca: localBusca, palavra:palavra }});    
    }

    getPesquisaCompleta(ano, recurso, relator, tributo){
        return this.http.get<Array<PesquisaResolucaoEmenta>>(`${environment.serverHost}/resolucaoEmentaService/getCompleta/`, 
        {params: {ano: ano, recurso: recurso, relator: relator, tributo: tributo }});    
    }

}
