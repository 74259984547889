import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConselheiroApi {
    constructor(private http: HttpClient) { }

    getConselheiros(){
        return this.http.get<Array<String>>(`${environment.serverHost}/conselheirosService/conselheiros`)
    }

    getConselheirosByTerm(term){
        return this.http.get<Array<String>>(`${environment.serverHost}/conselheirosService/conselheirosByTerm`,{params: {term: term}})
    }

}
