import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { AlertService } from './alert.service';
import { RequerenteApi } from '../requerente/requerente-api';
import { MovimentoProcessoApi } from '../movimento-processo/movimento-processo-api';
import { DocumentoFormatado } from '../@shared/service/documento-formatado.service';
import { ConselheiroApi } from '../pesquisa-resolucao-ementa/conselheiro/conselheiro-api';
import { ResolucaoApi } from '../pesquisa-resolucao-ementa/resolucao/resolucao-api';
import { PesquisaResolucaoEmentaApi } from '../pesquisa-resolucao-ementa/pesquisa-resolucao-ementa-api';
import { DownloadService } from '../@shared/service/download.service';
// import { KeycloakService } from './keycloak/keycloak.service';
// import { KeycloakInterceptor } from './keycloak/keycloak.interceptor';


registerLocaleData(localeBr)

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    // KeycloakService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: KeycloakInterceptor,
    //   multi: true
    // },

    // UserService,
    AlertService,
    { provide: LOCALE_ID, useValue: 'pt' },
    RequerenteApi,
    MovimentoProcessoApi,
    DocumentoFormatado,
    ConselheiroApi, 
    ResolucaoApi, 
    PesquisaResolucaoEmentaApi, 
    DownloadService
    // AuthGuard,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule')
  }
}

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`)
  }
}
