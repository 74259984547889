import { Injectable } from "@angular/core";

@Injectable()
export class DownloadService {
  
  downloadRelatorio(blob, filename) {
    let a = document.createElement("a")
    //var blob = new Blob([content], { type: mediaType });
    let url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url);
  }
}