import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'maxLength'
})

export class MaxLengthPipe implements PipeTransform {
	transform(text: string = '', length: number = 10): string {
		return `${text.substring(0, length)}${text.length > length ? '...' : ''}`
	}
}
