import { Component, Input} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { ResolucaoApi } from '../../../pesquisa-resolucao-ementa/resolucao/resolucao-api';
import { AlertService } from '../../../@core/alert.service';
import { DownloadService } from '../../service/download.service';
import { DocumentoFormatado } from '../../service/documento-formatado.service';

@Component({
  selector: 'app-situacao-processo',
  templateUrl: './situacao-processo.html',
  styleUrls: ['./situacao-processo.css']
})
export class SituacaoProcessoComponent{
  
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share())

  processo: number;

  @Input() movimentoProcesso;
  
  // maskProcesso = {mask: [/\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,'.', /\d/, /\d/, '-', /\d/], guide : false};
  maskProcesso = {mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/,/\d/], guide : false};
  maskProcessoPipe = '9.999999.99-9';

  maskCPFPipe = '999.999.999-99';
  maskCNPJPipe = '99.999.999/9999-99';

  page :number = 1;
  currentPage = 1;
  
  constructor(private resolucaoApi: ResolucaoApi,
              private alertService: AlertService,
              private downloadService: DownloadService,
              private documentoFormatado: DocumentoFormatado) {
  }


  downloadResolucao(numeroResolucao){
    this.loading.next(true);
    this.resolucaoApi.downloadResolucao(numeroResolucao)
    .subscribe((response) => {      
      this.downloadService.downloadRelatorio(response.body, numeroResolucao+"."+(response.body.type=='application/pdf' ? 'pdf' : 'doc'))
    }, (response) => {
      this.alertService.danger("Não foi possível baixar o arquivo da resolução "+numeroResolucao);      
      this.loading.next(false)
    }, () => this.loading.next(false));
  }

}
