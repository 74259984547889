import { Injectable } from '@angular/core'

import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PesquisaResolucaoEmenta } from '../pesquisa-resolucao-ementa';

@Injectable()
export class ResolucaoApi {
    constructor(private http: HttpClient) { }

    getAnos(){
        return this.http.get<Array<Number>>(`${environment.serverHost}/resolucaoService/anos`);
    }

    getAnosByTerm(term){
        return this.http.get<Array<Number>>(`${environment.serverHost}/resolucaoService/anosByTerm`,{params: {term: term}});
    }

    getNumerosResolucoes(){
        return this.http.get<Array<String>>(`${environment.serverHost}/resolucaoService/numeroResolucoes`);
    }

    getNumerosResolucoesByTerm(term){
        return this.http.get<Array<String>>(`${environment.serverHost}/resolucaoService/numeroResolucoesByTerm`, {params: {term: term}});
    }
    
    getPesquisaByNumeroResolucao(){
        return this.http.get<Array<PesquisaResolucaoEmenta>>(`${environment.serverHost}/resolucaoEmentaService/numeroResolucao`);
    }

    downloadResolucao(nroResolucao){
        return this.http.get(`${environment.serverHost}/resolucaoService/downloadResolucao`, {params: {nroResolucao: nroResolucao}, observe: 'response', responseType: 'blob'});
    }
}
