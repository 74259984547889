import { Injectable } from '@angular/core'

import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MovimentoProcesso } from './movimento-processo';

@Injectable()
export class MovimentoProcessoApi {
    constructor(private http: HttpClient) { }

    getMovimentacaoProcesso(processo){
        return this.http.get<MovimentoProcesso>(`${environment.serverHost}/processoService/movimentoProcesso/${processo}`);    
    }


}
