import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

@Component({
	selector: 'max-length',
	template: `
		<span [ngbPopover]="text" placement="bottom" triggers="mouseenter:mouseleave">{{text.substring(0, length)}}{{text.length > length ? '...' : ''}}</span>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxLengthComponent {
	@Input() text = ''
	@Input() length = 10
}
