import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'indexer'
})

export class IndexerPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		let retorno = {}

		value.forEach(element => {
			retorno[element[args[0]]] = element
		});

		return retorno
	}
}