import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core'

@Component({
	selector: 'graphic-bars',
	template: `
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" [attr.viewBox]="'0 0 '+ boxX + ' ' + boxY" [attr.enable-background]="'new 0 0 '+ boxX + ' ' + boxY" xml:space="preserve">
		<ng-container *ngFor="let numX of barMatrix; let indexX = index">
			<text *ngFor="let text of textMatrix[indexX]; let textIndexY = index" [attr.transform]="'translate(' + ((indexX + 1) * deltaX ) + ' ' + ((textIndexY + 1) * deltaY) + ')'" text-anchor="middle" fill="#58585A" font-family="'Source Sans Pro'" font-size="14">{{text}}</text>
			<rect *ngFor="let numY of numX; let indexY = index" [attr.x]="(deltaX / 2 + 4) + indexX * deltaX" [attr.y]="startYBars + indexY * deltaY" [attr.class]="numY ? 'consumido' : 'naoConsumido'" [attr.width]="deltaX - 8" [attr.height]="deltaY - 4" />
			<text *ngFor="let value of valueMatrix[indexX]; let valueIndexY = index" [attr.transform]="'translate(' + ((indexX + 1) * deltaX ) + ' ' + (startYValues + (valueIndexY + 1) * deltaY) + ')'" text-anchor="middle" fill="#009EE0" font-family="'Source Sans Pro'" font-size="15">{{value}}</text>
		</ng-container>
	</svg>
	`,
	styles: [`
		.consumido {
			fill: #009EE0; 
		}
		
		.naoConsumido {
			fill: #ECEDED; 
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphicBarsComponent implements OnChanges {
	@Input() data
	@Input() scale = 10

	deltaX = 66
	deltaY = 20

	startYBars: number
	startYValues: number

	boxY: number
	boxX: number

	barMatrix
	textMatrix
	valueMatrix
	ngOnChanges() {
		const maxValue = this.data
			.map(dataValue => Object.keys(dataValue.values)
				.map(key => dataValue.values[key])
				.reduce((acc, value) => acc > value ? acc : value, 0))
			.reduce((acc, value) => acc > value ? acc : value, 0)

		this.textMatrix = this.data
			.map(dataValue => Object.keys(dataValue.keys).map(key => dataValue.keys[key]))

		this.valueMatrix = this.data
			.map(dataValue => Object.keys(dataValue.values).map(key => dataValue.values[key]))

		this.barMatrix = this.valueMatrix
			.map(dataValue => Array(this.scale).fill(1).map((value, index) => {
				const scale = maxValue / this.scale
				const bars = Math.round(dataValue / scale)
				return (this.scale - index - 1) < bars
			}))

		this.startYBars = (this.textMatrix[0].length + 1) * this.deltaY
		this.startYValues = this.startYBars + this.scale * this.deltaY
		this.boxY = this.startYValues + (this.valueMatrix[0].length + 1) * this.deltaY
		this.boxX = (this.barMatrix.length + 1) * this.deltaX
	}
}
