import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core'

@Component({
	selector: 'app-odometer',
	template: `
	<div class="digit" *ngFor="let digit of stringNumber; let i = index">
		<div class="digit-container" [ngStyle]="changeStyle(i)">{{digit}} 9 8 7 6 5 4 3 2 1 0</div>
	</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OdometerComponent implements OnChanges {
	@Input() number: number
	stringNumber: string[]

	ngOnChanges() {
		if (this.number == null) return
		this.stringNumber = !isNaN(this.number) ? this.number.toString().replace('.', ',').split('') : this.number.toString().split('')
	}

	changeStyle(index) {
		const digitTime = this.stringNumber.length - index + 4
		return { '-webkit-animation-duration': `${digitTime}00ms`, 'animation-duration': `${digitTime}00ms` }
	}
}
