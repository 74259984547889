import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// import { AuthGuard } from './@core/auth-guard.service';

const routes: Routes = [
	{ path: '', redirectTo: 'movimentacaoProcesso', pathMatch: 'full' },
	{ path: 'movimentacaoProcesso', loadChildren: './movimento-processo/movimento-processo.module#PesquisaResolucaoEmentaModule' },
	{ path: 'resolucaoEmenta', loadChildren: './pesquisa-resolucao-ementa/pesquisa-resolucao-ementa.module#PesquisaResolucaoEmentaModule'},
	{ path: 'requerente', loadChildren: './requerente/requerente.module#RequerenteModule' }
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
