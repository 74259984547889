import 'lodash' // Global lodash

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { KeycloakService } from './app/@core/keycloak/keycloak.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
// set keycloack json first
// KeycloakService.init({ onLoad: 'login-required', checkLoginIframe: false })
//   .then(() => {
//     platformBrowserDynamic().bootstrapModule(AppModule)
//   })
//   .catch(err => alert(err));