import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'truncate'
})

export class TruncatePipe implements PipeTransform {
	transform(value: any = 0, decimal: string = ''): any {
		return !!value ? value.toFixed(decimal.length).replace(`.${decimal}`, '') : 0
	}
}
