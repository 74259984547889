import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'ObjectToArray'
})
export class ObjectToArrayPipe implements PipeTransform {
  transform(obj: any): object {
    const temp = []
    for (let key in obj) {
      if (obj[key]) { temp.push(obj[key]) }
    }
    return temp
  }
}
