import { Injectable } from "@angular/core";

@Injectable()
export class DocumentoFormatado {
  
  getDocumentoFormatado(documento, tipo){
    if(documento){
      if(tipo == "CNPJ"){
        return documento.toString().padStart(14, '0');
      }
      if(tipo == "CPF"){
        return documento.toString().padStart(11, '0');
      }
    }    
  }
  
}